// @flow
import { callAPI } from '../../../middleware';
import { getBaseUrl } from '../../../helpers';

import type { ThunkwithAction } from '../../../types';

export const LOAD_FORECAST_ACTIVITIES_DATA_FOR_MOVIE_REQUEST = 'LOAD_FORECAST_ACTIVITIES_DATA_FOR_MOVIE_REQUEST';
export const LOAD_FORECAST_ACTIVITIES_DATA_FOR_MOVIE_SUCCESS = 'LOAD_FORECAST_ACTIVITIES_DATA_FOR_MOVIE_SUCCESS';
export const LOAD_FORECAST_ACTIVITIES_DATA_FOR_MOVIE_FAILURE = 'LOAD_FORECAST_ACTIVITIES_DATA_FOR_MOVIE_FAILURE';

type LoadForecastActivitiesDataForMovieRequest = {
  type: typeof LOAD_FORECAST_ACTIVITIES_DATA_FOR_MOVIE_REQUEST,
};

const loadForecastActivitiesDataForMovieRequest = (): LoadForecastActivitiesDataForMovieRequest => {
  return {
    type: LOAD_FORECAST_ACTIVITIES_DATA_FOR_MOVIE_REQUEST,
  };
};

type LoadForecastActivitiesDataForMovieSuccess = {
  type: typeof LOAD_FORECAST_ACTIVITIES_DATA_FOR_MOVIE_SUCCESS,
  payload: Object,
};

const loadForecastActivitiesDataForMovieSuccess = (activities_data: Object): LoadForecastActivitiesDataForMovieSuccess => {
  return {
    type: LOAD_FORECAST_ACTIVITIES_DATA_FOR_MOVIE_SUCCESS,
    payload: activities_data, //sort ascending by title before returning
  };
};

type LoadForecastActivitiesDataForMovieFailure = {
  type: typeof LOAD_FORECAST_ACTIVITIES_DATA_FOR_MOVIE_FAILURE,
  error: string,
};

const loadForecastActivitiesDataForMovieFailure = (error: string): LoadForecastActivitiesDataForMovieFailure => {
  return {
    type: LOAD_FORECAST_ACTIVITIES_DATA_FOR_MOVIE_FAILURE,
    error,
  };
};

export type ForecastActivitiesDataForMovieActions =
  | LoadForecastActivitiesDataForMovieRequest
  | LoadForecastActivitiesDataForMovieSuccess
  | LoadForecastActivitiesDataForMovieFailure;

export const loadForecastActivitiesDataForMovie = (forecastMovieId: string): ThunkwithAction<ForecastActivitiesDataForMovieActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadForecastActivitiesDataForMovieRequest());

    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/forecast/${forecastMovieId}/activities-data`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadForecastActivitiesDataForMovieSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadForecastActivitiesDataForMovieFailure(error));
    return error;
  }
};
