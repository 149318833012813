// @flow
// TODO: replace callAPI with callAPIv2
import { callAPI } from '../../../middleware';
import { getBaseUrl } from '../../../helpers';

import type { ThunkwithAction } from '../../../types';

export const LOAD_FORECAST_GENDER_FOR_MOVIE_REQUEST = 'LOAD_FORECAST_GENDER_FOR_MOVIE_REQUEST';
export const LOAD_FORECAST_GENDER_FOR_MOVIE_SUCCESS = 'LOAD_FORECAST_GENDER_FOR_MOVIE_SUCCESS';
export const LOAD_FORECAST_GENDER_FOR_MOVIE_FAILURE = 'LOAD_FORECAST_GENDER_FOR_MOVIE_FAILURE';

type LoadForecastGenderForMovieRequest = {
  type: typeof LOAD_FORECAST_GENDER_FOR_MOVIE_REQUEST,
};

const loadForecastGenderForMovieRequest = (): LoadForecastGenderForMovieRequest => {
  return {
    type: LOAD_FORECAST_GENDER_FOR_MOVIE_REQUEST,
  };
};

type LoadForecastGenderForMovieSuccess = {
  type: typeof LOAD_FORECAST_GENDER_FOR_MOVIE_SUCCESS,
  payload: Object,
};

const loadForecastGenderForMovieSuccess = (genders: Object): LoadForecastGenderForMovieSuccess => {
  return {
    type: LOAD_FORECAST_GENDER_FOR_MOVIE_SUCCESS,
    payload: genders, //sort ascending by title before returning
  };
};

type LoadForecastGenderForMovieFailure = {
  type: typeof LOAD_FORECAST_GENDER_FOR_MOVIE_FAILURE,
  error: string,
};

const loadForecastGenderForMovieFailure = (error: string): LoadForecastGenderForMovieFailure => {
  return {
    type: LOAD_FORECAST_GENDER_FOR_MOVIE_FAILURE,
    error,
  };
};

export type ForecastGenderMoviesActions =
  | LoadForecastGenderForMovieRequest
  | LoadForecastGenderForMovieSuccess
  | LoadForecastGenderForMovieFailure;

export const loadForecastGenderForMovie = (forecastId: string, filter: string): ThunkwithAction<ForecastGenderMoviesActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadForecastGenderForMovieRequest());
    var filterIds = "";
    if(filter && filter.length > 0){
      filterIds = "?filter_ids=" + filter;
    }

    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/forecast/${forecastId}/gender${filterIds}`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadForecastGenderForMovieSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadForecastGenderForMovieFailure(error));
    return error;
  }
};
