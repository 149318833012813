// @flow
// TODO: replace callAPI with callAPIv2
import { callAPI } from '../../../middleware';
import { getBaseUrl } from '../../../helpers';

import type { ThunkwithAction } from '../../../types';

export const LOAD_FORECAST_AGE_FOR_MOVIE_REQUEST = 'LOAD_FORECAST_AGE_FOR_MOVIE_REQUEST';
export const LOAD_FORECAST_AGE_FOR_MOVIE_SUCCESS = 'LOAD_FORECAST_AGE_FOR_MOVIE_SUCCESS';
export const LOAD_FORECAST_AGE_FOR_MOVIE_FAILURE = 'LOAD_FORECAST_AGE_FOR_MOVIE_FAILURE';

type LoadForecastAgeForMovieRequest = {
  type: typeof LOAD_FORECAST_AGE_FOR_MOVIE_REQUEST,
};

const loadForecastAgeForMovieRequest = (): LoadForecastAgeForMovieRequest => {
  return {
    type: LOAD_FORECAST_AGE_FOR_MOVIE_REQUEST,
  };
};

type LoadForecastAgeForMovieSuccess = {
  type: typeof LOAD_FORECAST_AGE_FOR_MOVIE_SUCCESS,
  payload: Object,
};

const loadForecastAgeForMovieSuccess = (ages: Object): LoadForecastAgeForMovieSuccess => {
  return {
    type: LOAD_FORECAST_AGE_FOR_MOVIE_SUCCESS,
    payload: ages, //sort ascending by title before returning
  };
};

type LoadForecastAgeForMovieFailure = {
  type: typeof LOAD_FORECAST_AGE_FOR_MOVIE_FAILURE,
  error: string,
};

const loadForecastAgeForMovieFailure = (error: string): LoadForecastAgeForMovieFailure => {
  return {
    type: LOAD_FORECAST_AGE_FOR_MOVIE_FAILURE,
    error,
  };
};

export type ForecastAgeActions =
  | LoadForecastAgeForMovieRequest
  | LoadForecastAgeForMovieSuccess
  | LoadForecastAgeForMovieFailure;

export const loadForecastAgeForMovie = (forecastMovieId: string, filter: string): ThunkwithAction<ForecastAgeActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadForecastAgeForMovieRequest());
    let filterIds = "";
    if(filter && filter.length > 0){
      filterIds = "?filter_ids=" + filter;
    }
    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/forecast/${forecastMovieId}/age${filterIds}`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadForecastAgeForMovieSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadForecastAgeForMovieFailure(error));
    return error;
  }
};
