// @flow
import * as actions from '../../../actions/forecast/common/forecasts';
import type { ForecastsActions } from '../../../actions/forecast/common/forecasts';

type State = {
  isFetching: boolean,
  items: Array<any>,
};

const initial = {
  isFetching: false,
  items: [],
};

export const forecasts = (
  state: State = initial,
  action: ForecastsActions
) => {
  switch (action.type) {
    case actions.LOAD_FORECASTS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_FORECASTS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_FORECASTS_SUCCESS:
      return {
        ...state,
        items: action.payload.sort(function(a,b){ var x = a.title < b.title? -1:1; return x; }), //sort movies alphabetically
        isFetching: false,
      };
    default:
      return state;
  }
};
