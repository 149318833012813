// @flow
import * as actions from '../../../actions/forecast/common/forecastGender';
import {ForecastGenderMoviesActions} from "../../../actions/forecast/common/forecastGender";

type State = {
  isFetching: boolean,
  genderResults: Array<any>,
  message: String
};

const initialState = {
  isFetching: false,
  genderResults: [],
  message: ''
};

export const forecastGender = (
  state: State = initialState,
  action: ForecastGenderMoviesActions
) => {
  switch (action.type) {
    case 'RESET_MOVIE_PROFILING_DATA':
    case 'RESET_CINEMA_AUDIENCE_DATA':
    case 'RESET_MOVIE_BY_AUDIENCE_DATA':
    case 'RESET_FORECAST_MOVIE_PROFILING_DATA':
      return {
        ...state,
        isFetching: false,
        genderResults: []
      }
    case actions.LOAD_FORECAST_GENDER_FOR_MOVIE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_FORECAST_GENDER_FOR_MOVIE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_FORECAST_GENDER_FOR_MOVIE_SUCCESS:
      return {
        ...state,
        genderResults: action.payload.gender,
        message: action.payload.message,
        isFetching: false,
      };
    default:
      return state;
  }
};
