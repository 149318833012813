// @flow
import { callAPI } from '../../../middleware';
import { getBaseUrl } from '../../../helpers';

import type { ThunkwithAction } from '../../../types';

export const LOAD_AGGREGATED_INDEX_STAT_FOR_FORECAST_REQUEST = 'LOAD_AGGREGATED_INDEX_STAT_FOR_FORECAST_REQUEST';
export const LOAD_AGGREGATED_INDEX_STAT_FOR_FORECAST_SUCCESS = 'LOAD_AGGREGATED_INDEX_STAT_FOR_FORECAST_SUCCESS';
export const LOAD_AGGREGATED_INDEX_STAT_FOR_FORECAST_FAILURE = 'LOAD_AGGREGATED_INDEX_STAT_FOR_FORECAST_FAILURE';

type LoadAggregatedIndexStatForForecastRequest = {
  type: typeof LOAD_AGGREGATED_INDEX_STAT_FOR_FORECAST_REQUEST,
};

const loadAggregatedIndexStatForForecastRequest = (): LoadAggregatedIndexStatForForecastRequest => {
  return {
    type: LOAD_AGGREGATED_INDEX_STAT_FOR_FORECAST_REQUEST,
  };
};

type LoadAggregatedIndexStatForForecastSuccess = {
  type: typeof LOAD_AGGREGATED_INDEX_STAT_FOR_FORECAST_SUCCESS,
  payload: Object,
};

const loadAggregatedIndexStatForForecastSuccess = (audience: Object): LoadAggregatedIndexStatForForecastSuccess => {
  return {
    type: LOAD_AGGREGATED_INDEX_STAT_FOR_FORECAST_SUCCESS,
    payload: audience,
  };
};

type LoadAggregatedIndexStatForForecastFailure = {
  type: typeof LOAD_AGGREGATED_INDEX_STAT_FOR_FORECAST_FAILURE,
  error: string,
};

const loadAggregatedIndexStatForForecastFailure = (error: string): LoadAggregatedIndexStatForForecastFailure => {
  return {
    type: LOAD_AGGREGATED_INDEX_STAT_FOR_FORECAST_FAILURE,
    error,
  };
};

export type AggregatedIndexStatForForecastActions =
  | LoadAggregatedIndexStatForForecastRequest
  | LoadAggregatedIndexStatForForecastSuccess
  | LoadAggregatedIndexStatForForecastFailure;

export const loadAggregatedIndexStatForForecast = (forecastMovieId: string, filter: string): ThunkwithAction<AggregatedIndexStatForForecastActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadAggregatedIndexStatForForecastRequest());

    let filterIds = "";
    if(filter.length > 0){
      filterIds = "?filter_ids=" + filter;
    }

    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/forecast/${forecastMovieId}/aggregated-forecast-index${filterIds}`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadAggregatedIndexStatForForecastSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadAggregatedIndexStatForForecastFailure(error));
    return error;
  }
};
