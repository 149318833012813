// @flow
import * as actions from '../../../actions/forecast/forecastMovieProfiling/forecastProductData';

import type { forecastProductDataForMovieActions } from '../../../actions/forecast/forecastMovieProfiling/forecastProductData';

type State = {
  isFetching: boolean,
  forecastProductDataForMovieResults: Array<any>,
};

const initialState = {
  isFetching: false,
  forecastProductDataForMovieResults: [],
};

export const forecastProductData = (
  state: State = initialState,
  action: forecastProductDataForMovieActions
) => {
  switch (action.type) {
    case 'RESET_FORECAST_MOVIE_PROFILING_DATA':
      return {
        ...state,
        isFetching: false,
        forecastProductDataForMovieResults: []
      }
    case actions.LOAD_FORECAST_PRODUCT_DATA_FOR_MOVIE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_FORECAST_PRODUCT_DATA_FOR_MOVIE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_FORECAST_PRODUCT_DATA_FOR_MOVIE_SUCCESS:
      return {
        ...state,
        forecastProductDataForMovieResults: action.payload.product_data,
        isFetching: false,
      };
    default:
      return state;
  }
};
