// @flow
import { callAPI } from '../../../middleware';
import { getBaseUrl } from '../../../helpers';

import type { ThunkwithAction } from '../../../types';

export const LOAD_FORECAST_FTA_TV_CONSUMPTION_FOR_MOVIE_REQUEST = 'LOAD_FORECAST_FTA_TV_CONSUMPTION_FOR_MOVIE_REQUEST';
export const LOAD_FORECAST_FTA_TV_CONSUMPTION_FOR_MOVIE_SUCCESS = 'LOAD_FORECAST_FTA_TV_CONSUMPTION_FOR_MOVIE_SUCCESS';
export const LOAD_FORECAST_FTA_TV_CONSUMPTION_FOR_MOVIE_FAILURE = 'LOAD_FORECAST_FTA_TV_CONSUMPTION_FOR_MOVIE_FAILURE';

type LoadForecastFTATVConsumptionForMovieRequest = {
  type: typeof LOAD_FORECAST_FTA_TV_CONSUMPTION_FOR_MOVIE_REQUEST,
};

const loadForecastFTATVConsumptionForMovieRequest = (): LoadForecastFTATVConsumptionForMovieRequest => {
  return {
    type: LOAD_FORECAST_FTA_TV_CONSUMPTION_FOR_MOVIE_REQUEST,
  };
};

type LoadForecastFTATVConsumptionForMovieSuccess = {
  type: typeof LOAD_FORECAST_FTA_TV_CONSUMPTION_FOR_MOVIE_SUCCESS,
  payload: Object,
};

const loadForecastFTATVConsumptionForMovieSuccess = (fta_tv_consumption: Object): LoadForecastFTATVConsumptionForMovieSuccess => {
  return {
    type: LOAD_FORECAST_FTA_TV_CONSUMPTION_FOR_MOVIE_SUCCESS,
    payload: fta_tv_consumption,
  };
};

type LoadForecastFTATVConsumptionForMovieFailure = {
  type: typeof LOAD_FORECAST_FTA_TV_CONSUMPTION_FOR_MOVIE_FAILURE,
  error: string,
};

const loadForecastFTATVConsumptionForMovieFailure = (error: string): LoadForecastFTATVConsumptionForMovieFailure => {
  return {
    type: LOAD_FORECAST_FTA_TV_CONSUMPTION_FOR_MOVIE_FAILURE,
    error,
  };
};

export type ForecastFTAConsumptionForMovieActions =
  | LoadForecastFTATVConsumptionForMovieRequest
  | LoadForecastFTATVConsumptionForMovieSuccess
  | LoadForecastFTATVConsumptionForMovieFailure;

export const loadForecastFTATVConsumptionForMovie = (forecastMovieId: string, filter: string): ThunkwithAction<ForecastFTAConsumptionForMovieActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadForecastFTATVConsumptionForMovieRequest());

    var filterIds = "";
    if(filter && filter.length > 0){
      filterIds = "?filter_ids=" + filter;
    }
    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/forecast/${forecastMovieId}/fta-tv-consumption${filterIds}`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadForecastFTATVConsumptionForMovieSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadForecastFTATVConsumptionForMovieFailure(error));
    return error;
  }
};
