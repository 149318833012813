// @flow
import * as actions from '../../../actions/forecast/forecastMovieProfiling/forecastConsumerProfiling';
import type {
  ForecastConsumerProfilingForMovieActions
} from "../../../actions/forecast/forecastMovieProfiling/forecastConsumerProfiling";

type State = {
  isFetching: boolean,
  forecastConsumerProfilingForMovieResults: Array<any>,
};

const initialState = {
  isFetching: false,
  forecastConsumerProfilingForMovieResults: [],
};

export const forecastConsumerProfiling = (
  state: State = initialState,
  action: ForecastConsumerProfilingForMovieActions
) => {
  switch (action.type) {
    case 'RESET_FORECAST_MOVIE_PROFILING_DATA':
        return {
          ...state,
          isFetching: false,
          forecastConsumerProfilingForMovieResults: []
        }
    case actions.LOAD_FORECAST_CONSUMER_PROFILING_FOR_MOVIE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_FORECAST_CONSUMER_PROFILING_FOR_MOVIE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_FORECAST_CONSUMER_PROFILING_FOR_MOVIE_SUCCESS:
      return {
        ...state,
        forecastConsumerProfilingForMovieResults: action.payload.consumer_profiling,
        isFetching: false,
      };
    default:
      return state;
  }
};
