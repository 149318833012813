// @flow
import * as actions from '../../../actions/forecast/common/forecastTotalAudience';

import type { TotalAudienceForForecastActions } from '../../../actions/forecast/common/forecastTotalAudience';

type State = {
  isFetching: boolean,
  value: string,
  message: String,
};

const initialState = {
  isFetching: false,
  value: "",
  message: ""
};

export const totalAudienceForForecast = (
  state: State = initialState,
  action: TotalAudienceForForecastActions
) => {
  switch (action.type) {
    case 'RESET_FORECAST_MOVIE_PROFILING_DATA':
      return {
        ...state,
        isFetching: false,
        value: ''
      };
    case actions.LOAD_TOTAL_AUDIENCE_FOR_FORECAST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_TOTAL_AUDIENCE_FOR_FORECAST_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_TOTAL_AUDIENCE_FOR_FORECAST_SUCCESS:
      return {
        ...state,
        value: action.payload.audience,
        message: action.payload.message,
        isFetching: false,
      };
    default:
      return state;
  }
};
