// @flow
// TODO: replace callAPI with callAPIv2
import { callAPI } from '../../../middleware';
import { getBaseUrl } from '../../../helpers';

import type { ThunkwithAction } from '../../../types';

export const LOAD_FORECAST_MOVIE_POSTER_REQUEST = 'LOAD_FORECAST_MOVIE_POSTER_REQUEST';
export const LOAD_FORECAST_MOVIE_POSTER_SUCCESS = 'LOAD_FORECAST_MOVIE_POSTER_SUCCESS';
export const LOAD_FORECAST_MOVIE_POSTER_FAILURE = 'LOAD_FORECAST_MOVIE_POSTER_FAILURE';

type LoadForecastMoviePosterRequest = {
  type: typeof LOAD_FORECAST_MOVIE_POSTER_REQUEST,
};

const loadForecastMoviePosterRequest = (): LoadForecastMoviePosterRequest => {
  return {
    type: LOAD_FORECAST_MOVIE_POSTER_REQUEST,
  };
};

type LoadForecastMoviePosterSuccess = {
  type: typeof LOAD_FORECAST_MOVIE_POSTER_SUCCESS,
  payload: Object,
};

const loadForecastMoviePosterSuccess = (forecastMoviePoster: String): LoadForecastMoviePosterSuccess => {
  return {
    type: LOAD_FORECAST_MOVIE_POSTER_SUCCESS,
    payload: forecastMoviePoster, //sort ascending by title before returning
  };
};

type LoadForecastMoviePosterFailure = {
  type: typeof LOAD_FORECAST_MOVIE_POSTER_FAILURE,
  error: string,
};

const loadForecastMoviePosterFailure = (error: string): LoadForecastMoviePosterFailure => {
  return {
    type: LOAD_FORECAST_MOVIE_POSTER_FAILURE,
    error,
  };
};

export type ForecastMoviePosterActions =
  | LoadForecastMoviePosterRequest
  | LoadForecastMoviePosterSuccess
  | LoadForecastMoviePosterFailure;

export const loadForecastMoviePoster = (forecastMovieId: string): ThunkwithAction<ForecastMoviePosterActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadForecastMoviePosterRequest());

    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/forecast/${forecastMovieId}/poster`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadForecastMoviePosterSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadForecastMoviePosterFailure(error));
    return error;
  }
};



