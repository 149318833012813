// @flow
import { callAPI } from '../../../middleware';
import { getBaseUrl } from '../../../helpers';

import type { ThunkwithAction } from '../../../types';

export const LOAD_FORECAST_PRODUCT_DATA_FOR_MOVIE_REQUEST = 'LOAD_FORECAST_PRODUCT_DATA_FOR_MOVIE_REQUEST';
export const LOAD_FORECAST_PRODUCT_DATA_FOR_MOVIE_SUCCESS = 'LOAD_FORECAST_PRODUCT_DATA_FOR_MOVIE_SUCCESS';
export const LOAD_FORECAST_PRODUCT_DATA_FOR_MOVIE_FAILURE = 'LOAD_FORECAST_PRODUCT_DATA_FOR_MOVIE_FAILURE';

type LoadForecastProductDataForMovieRequest = {
  type: typeof LOAD_FORECAST_PRODUCT_DATA_FOR_MOVIE_REQUEST,
};

const loadForecastProductDataForMovieRequest = (): loadForecastProductDataForMovieRequest => {
  return {
    type: LOAD_FORECAST_PRODUCT_DATA_FOR_MOVIE_REQUEST,
  };
};

type LoadForecastProductDataForMovieSuccess = {
  type: typeof LOAD_FORECAST_PRODUCT_DATA_FOR_MOVIE_SUCCESS,
  payload: Object,
};

const loadForecastProductDataForMovieSuccess = (product_data: Object): loadForecastProductDataForMovieSuccess => {
  return {
    type: LOAD_FORECAST_PRODUCT_DATA_FOR_MOVIE_SUCCESS,
    payload: product_data, //sort ascending by title before returning
  };
};

type LoadForecastProductDataForMovieFailure = {
  type: typeof LOAD_FORECAST_PRODUCT_DATA_FOR_MOVIE_FAILURE,
  error: string,
};

const loadForecastProductDataForMovieFailure = (error: string): loadForecastProductDataForMovieFailure => {
  return {
    type: LOAD_FORECAST_PRODUCT_DATA_FOR_MOVIE_FAILURE,
    error,
  };
};

export type ForecastProductDataForMovieActions =
  | LoadForecastProductDataForMovieRequest
  | LoadForecastProductDataForMovieSuccess
  | LoadForecastProductDataForMovieFailure;

export const loadForecastProductDataForMovie = (forecastMovieId: string): ThunkwithAction<ForecastProductDataForMovieActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadForecastProductDataForMovieRequest());

    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/forecast/${forecastMovieId}/product-data`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadForecastProductDataForMovieSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadForecastProductDataForMovieFailure(error));
    return error;
  }
};
