// @flow
import * as actions from '../../../actions/forecast/forecastMovieProfiling/forecastActivitiesData';
import type {ForecastMoviesActions} from "../../../actions/forecast/common/forecastMovies";

type State = {
  isFetching: boolean,
  forecastActivitiesDataForMovieResults: Array<any>,
};

const initialState = {
  isFetching: false,
  forecastActivitiesDataForMovieResults: [],
};

export const forecastActivitiesData = (
  state: State = initialState,
  action: ForecastMoviesActions
) => {
  switch (action.type) {
    case 'RESET_FORECAST_MOVIE_PROFILING_DATA':
      return {
        ...state,
        isFetching: false,
        forecastActivitiesDataForMovieResults: []
      }
    case actions.LOAD_FORECAST_ACTIVITIES_DATA_FOR_MOVIE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_FORECAST_ACTIVITIES_DATA_FOR_MOVIE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_FORECAST_ACTIVITIES_DATA_FOR_MOVIE_SUCCESS:
      return {
        ...state,
        forecastActivitiesDataForMovieResults: action.payload.activities_data, //TODO Change this once API is changed from using dummy data
        isFetching: false,
      };
    default:
      return state;
  }
};
