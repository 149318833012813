// @flow
import * as actions from '../../../actions/forecast/common/forecastAge';

import type { ForecastAgeActions } from '../../../actions/forecast/common/forecastAge';

type State = {
  isFetching: boolean,
  ageResults: Array<any>,
  message: String
};

const initialState = {
  isFetching: false,
  ageResults: [],
  message: ''
};

export const forecastAge = (
  state: State = initialState,
  action: ForecastAgeActions
) => {
  switch (action.type) {
    case 'RESET_FORECAST_MOVIE_PROFILING_DATA':
        return {
          ...state,
          isFetching: false,
          ageResults: []
        }
    case actions.LOAD_FORECAST_AGE_FOR_MOVIE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_FORECAST_AGE_FOR_MOVIE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_FORECAST_AGE_FOR_MOVIE_SUCCESS:
      return {
        ...state,
        ageResults: action.payload.age,
        message: action.payload.message,
        isFetching: false,
      };
    default:
      return state;
  }
};
