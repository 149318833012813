// @flow
import { combineReducers } from 'redux';

import options from './options';
import { movies } from './common';
import { genres } from './common/genres';
import { ratings } from './common/ratings';
import { moviePoster } from './common';
import { gender } from './common';
import { filters } from './common/filters';
import { showUpdatedMessage } from './common/showUpdatedMessage';
import { selectedFilters } from './common/selectedFilters';
import { selectedGenres } from "./common/selectedGenres";
import { selectedRatings } from "./common/selectedRatings";
import { age } from './movieProfiling';
import { totalAudience } from './common/totalAudience';
import { audienceByMarket } from './movieByAudience/audienceByMarket';
import { audienceByWeekStacked } from './movieByAudience/audienceByWeekStacked';
import { ftaTvConsumption } from './movieByAudience/ftaTvConsumption';
import { totalAudienceStat } from './movieByAudience/totalAudienceStat';
import { uniqueAudienceStat } from './movieByAudience/uniqueAudienceStat';
import { audienceByWeek } from './movieProfiling/audienceByWeek';
import { consumerProfiling } from './movieProfiling/consumerProfiling';
import { activitiesData } from './movieProfiling/activitiesData';
import { productData } from './movieProfiling/productData';
import { filterSearch } from './common/filterSearch';

import { ftaTvConsumptionByDate } from './cinemaAudience/ftaTvConsumptionByDate';
import { totalAudienceStatByDate } from './cinemaAudience/totalAudienceStatByDate';
import { uniqueAudienceStatByDate } from './cinemaAudience/uniqueAudienceStatByDate';

import { audienceByGenreIndexByDate } from './cinemaAudience/audienceByGenreIndexByDate';
import { audienceByGenreReachByDate } from './cinemaAudience/audienceByGenreReachByDate';
import { audienceByMarketByDate } from './cinemaAudience/audienceByMarketByDate';
import { audienceByTopTenMovieByDate } from './cinemaAudience/audienceByTopTenMovieByDate';

import { ageByGenre } from './genreByAudience/ageByGenre';
import { audienceByMarketByDateAndGenre } from './genreByAudience/audienceByMarketByDateAndGenre';
import { audienceByTopTenMovieByDateAndGenre } from './genreByAudience/audienceByTopTenMovieByDateAndGenre';
import { ftaTvConsumptionByDateAndGenre } from './genreByAudience/ftaTvConsumptionByDateAndGenre';
import { genderForGenre } from './genreByAudience/genderForGenre';
import { totalAudienceStatByDateAndGenre } from './genreByAudience/totalAudienceStatByDateAndGenre';
import { uniqueAudienceStatByDateAndGenre } from './genreByAudience/uniqueAudienceStatByDateAndGenre';
import { aggregatedIndexStatByDateAndGenre } from "./genreByAudience/aggregatedIndexStatByDateAndGenre";

import { campaigns } from './campaignByAudience/campaigns';
import { totalAudienceStatForCampaign } from './campaignByAudience/totalAudienceStatForCampaign';
import { audienceRfStatForCampaign } from './campaignByAudience/audienceRfStatForCampaign';
import { aggregatedIndexStatForCampaign } from './campaignByAudience/aggregatedIndexStatForCampaign';
import { audienceByWeekForCampaign } from './campaignByAudience/audienceByWeekForCampaign';
import { totalAudienceForCampaign } from './campaignByAudience/totalAudienceForCampaign';

import { dataUpdateInfo } from './common/dataUpdateInfo';
import { forecasts } from './forecast/common/forecasts';
import { forecastMoviePoster } from './forecast/common/forecastMoviePoster';
import { forecastGender }  from './forecast/common/forecastGender';
import { forecastAge }  from './forecast/common/forecastAge';
import { forecastActivitiesData } from "./forecast/forecastMovieProfiling/forecastActivitiesData";
import { forecastConsumerProfiling } from "./forecast/forecastMovieProfiling/forecastConsumerProfiling";
import { forecastProductData } from "./forecast/forecastMovieProfiling/forecastProductData";
import { forecastFTATVConsumption } from "./forecast/common/forecastFTATVConsumption";
import { forecastInfoStat } from "./forecast/common/forecastInfoStat";
import { forecastMovies } from "./forecast/common/forecastMovies";
import { totalAudienceForForecast } from "./forecast/common/forecastTotalAudience";
import { aggregatedIndexStatForForecast } from "./forecast/forecastAudienceByMovie/aggregatedIndexStatForForecast";

const campaignCreatorReducers = combineReducers<_, { +type: string }>({
  ftaTvConsumptionByDate,
  totalAudienceStatByDate,
  uniqueAudienceStatByDate,
  audienceByGenreIndexByDate,
  audienceByGenreReachByDate,
  audienceByMarketByDate,
  audienceByTopTenMovieByDate,
  audienceByWeek,
  totalAudience,
  movies,
  genres,
  ratings,
  moviePoster,
  consumerProfiling,
  productData,
  activitiesData,
  audienceByMarket,
  audienceByWeekStacked,
  ftaTvConsumption,
  totalAudienceStat,
  uniqueAudienceStat,
  gender,
  age,
  options,
  filters,
  selectedFilters,
  selectedGenres,
  selectedRatings,
  filterSearch,
  showUpdatedMessage,
  ageByGenre,
  audienceByMarketByDateAndGenre,
  audienceByTopTenMovieByDateAndGenre,
  ftaTvConsumptionByDateAndGenre,
  genderForGenre,
  totalAudienceStatByDateAndGenre,
  uniqueAudienceStatByDateAndGenre,
  aggregatedIndexStatByDateAndGenre,
  campaigns,
  totalAudienceStatForCampaign,
  audienceRfStatForCampaign,
  aggregatedIndexStatForCampaign,
  audienceByWeekForCampaign,
  totalAudienceForCampaign,
  dataUpdateInfo,
  forecasts,
  forecastMoviePoster,
  forecastGender,
  forecastAge,
  forecastActivitiesData,
  forecastConsumerProfiling,
  forecastProductData,
  forecastFTATVConsumption,
  forecastInfoStat,
  forecastMovies,
  totalAudienceForForecast,
  aggregatedIndexStatForForecast
});

export default campaignCreatorReducers;
