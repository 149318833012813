// @flow
// TODO: replace callAPI with callAPIv2
import { callAPI } from '../../../middleware';
import { getBaseUrl,sortByKey } from '../../../helpers';

import type { ThunkwithAction } from '../../../types';

export const LOAD_FORECASTS_REQUEST = 'LOAD_FORECASTS_REQUEST';
export const LOAD_FORECASTS_SUCCESS = 'LOAD_FORECASTS_SUCCESS';
export const LOAD_FORECASTS_FAILURE = 'LOAD_FORECASTS_FAILURE';

type LoadForecastsRequest = {
  type: typeof LOAD_FORECASTS_REQUEST,
};

const loadForecastsRequest = (): LoadForecastsRequest => {
  return {
    type: LOAD_FORECASTS_REQUEST,
  };
};

type LoadForecastsSuccess = {
  type: typeof LOAD_FORECASTS_SUCCESS,
  payload: Object,
};

const loadForecastsSuccess = (campaigns: Object): LoadForecastsSuccess => {
  return {
    type: LOAD_FORECASTS_SUCCESS,
    payload: sortByKey(campaigns, 'title'), //sort ascending by title before returning
  };
};

type LoadForecastsFailure = {
  type: typeof LOAD_FORECASTS_FAILURE,
  error: string,
};

const loadForecastsFailure = (error: string): LoadForecastsFailure => {
  return {
    type: LOAD_FORECASTS_FAILURE,
    error,
  };
};

export type ForecastsActions =
  | LoadForecastsRequest
  | LoadForecastsSuccess
  | LoadForecastsFailure;

export const loadForecasts = (): ThunkwithAction<ForecastsActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadForecastsRequest());

    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/forecasts`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadForecastsSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadForecastsFailure(error));
    return error;
  }
};
