// @flow
import { callAPI } from '../../../middleware';
import { getBaseUrl } from '../../../helpers';

import type { ThunkwithAction } from '../../../types';

export const LOAD_INFO_FOR_FORECAST_REQUEST = 'LOAD_INFO_FOR_FORECAST_REQUEST';
export const LOAD_INFO_FOR_FORECAST_SUCCESS = 'LOAD_INFO_FOR_FORECAST_SUCCESS';
export const LOAD_INFO_FOR_FORECAST_FAILURE = 'LOAD_INFO_FOR_FORECAST_FAILURE';

type LoadInfoForForecastRequest = {
  type: typeof LOAD_INFO_FOR_FORECAST_REQUEST,
};

const loadInfoForForecastRequest = (): LoadInfoForForecastRequest => {
  return {
    type: LOAD_INFO_FOR_FORECAST_REQUEST,
  };
};

type LoadInfoForForecastSuccess = {
  type: typeof LOAD_INFO_FOR_FORECAST_SUCCESS,
  payload: Object,
};

const loadInfoForForecastSuccess = (forecastInfo: Object): LoadInfoForForecastSuccess => {
  return {
    type: LOAD_INFO_FOR_FORECAST_SUCCESS,
    payload: forecastInfo,
  };
};

type LoadInfoForForecastFailure = {
  type: typeof LOAD_INFO_FOR_FORECAST_FAILURE,
  error: string,
};

const loadInfoForForecastFailure = (error: string): LoadInfoForForecastFailure => {
  return {
    type: LOAD_INFO_FOR_FORECAST_FAILURE,
    error,
  };
};

export type InfoForForecastActions =
  | LoadInfoForForecastRequest
  | LoadInfoForForecastSuccess
  | LoadInfoForForecastFailure;

export const loadInfoForForecast = (forecastMovieId: string): ThunkwithAction<InfoForForecastActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadInfoForForecastRequest());

    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/forecast/${forecastMovieId}/info`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadInfoForForecastSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadInfoForForecastFailure(error));
    return error;
  }
};
