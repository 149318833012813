// @flow
import { callAPI } from '../../../middleware';
import { getBaseUrl } from '../../../helpers';

import type { ThunkwithAction } from '../../../types';

export const LOAD_FORECAST_CONSUMER_PROFILING_FOR_MOVIE_REQUEST = 'LOAD_FORECAST_CONSUMER_PROFILING_FOR_MOVIE_REQUEST';
export const LOAD_FORECAST_CONSUMER_PROFILING_FOR_MOVIE_SUCCESS = 'LOAD_FORECAST_CONSUMER_PROFILING_FOR_MOVIE_SUCCESS';
export const LOAD_FORECAST_CONSUMER_PROFILING_FOR_MOVIE_FAILURE = 'LOAD_FORECAST_CONSUMER_PROFILING_FOR_MOVIE_FAILURE';

type LoadForecastConsumerProfilingForMovieRequest = {
  type: typeof LOAD_FORECAST_CONSUMER_PROFILING_FOR_MOVIE_REQUEST,
};

const loadForecastConsumerProfilingForMovieRequest = (): loadForecastConsumerProfilingForMovieRequest => {
  return {
    type: LOAD_FORECAST_CONSUMER_PROFILING_FOR_MOVIE_REQUEST,
  };
};

type LoadForecastConsumerProfilingForMovieSuccess = {
  type: typeof LOAD_FORECAST_CONSUMER_PROFILING_FOR_MOVIE_SUCCESS,
  payload: Object,
};

const loadForecastConsumerProfilingForMovieSuccess = (consumer_profiles: Object): loadForecastConsumerProfilingForMovieSuccess => {
  return {
    type: LOAD_FORECAST_CONSUMER_PROFILING_FOR_MOVIE_SUCCESS,
    payload: consumer_profiles, //sort ascending by title before returning
  };
};

type LoadForecastConsumerProfilingForMovieFailure = {
  type: typeof LOAD_FORECAST_CONSUMER_PROFILING_FOR_MOVIE_FAILURE,
  error: string,
};

const loadForecastConsumerProfilingForMovieFailure = (error: string): loadForecastConsumerProfilingForMovieFailure => {
  return {
    type: LOAD_FORECAST_CONSUMER_PROFILING_FOR_MOVIE_FAILURE,
    error,
  };
};

export type ForecastConsumerProfilingForMovieActions =
  | LoadForecastConsumerProfilingForMovieRequest
  | LoadForecastConsumerProfilingForMovieSuccess
  | LoadForecastConsumerProfilingForMovieFailure;

export const loadForecastConsumerProfilingForMovie = (forecastId: string): ThunkwithAction<ForecastConsumerProfilingForMovieActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadForecastConsumerProfilingForMovieRequest());

    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/forecast/${forecastId}/consumer-profiling`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadForecastConsumerProfilingForMovieSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadForecastConsumerProfilingForMovieFailure(error));
    return error;
  }
};
