// @flow
import { callAPI } from '../../middleware';
import { getBaseUrl } from '../../helpers';

import type { ThunkwithAction } from '../types';

export const LOAD_FTA_TV_CONSUMPTION_FOR_MOVIE_REQUEST = 'LOAD_FTA_TV_CONSUMPTION_FOR_MOVIE_REQUEST';
export const LOAD_FTA_TV_CONSUMPTION_FOR_MOVIE_SUCCESS = 'LOAD_FTA_TV_CONSUMPTION_FOR_MOVIE_SUCCESS';
export const LOAD_FTA_TV_CONSUMPTION_FOR_MOVIE_FAILURE = 'LOAD_FTA_TV_CONSUMPTION_FOR_MOVIE_FAILURE';

type LoadFtaTvConsumptionForMovieRequest = {
  type: typeof LOAD_FTA_TV_CONSUMPTION_FOR_MOVIE_REQUEST,
};

const loadFtaTvConsumptionForMovieRequest = (): LoadFtaTvConsumptionForMovieRequest => {
  return {
    type: LOAD_FTA_TV_CONSUMPTION_FOR_MOVIE_REQUEST,
  };
};

type LoadFtaTvConsumptionForMovieSuccess = {
  type: typeof LOAD_FTA_TV_CONSUMPTION_FOR_MOVIE_SUCCESS,
  payload: Object,
};

const loadFtaTvConsumptionForMovieSuccess = (fta_tv_consumption: Object): LoadFtaTvConsumptionForMovieSuccess => {
  return {
    type: LOAD_FTA_TV_CONSUMPTION_FOR_MOVIE_SUCCESS,
    payload: fta_tv_consumption,
  };
};

type LoadFtaTvConsumptionForMovieFailure = {
  type: typeof LOAD_FTA_TV_CONSUMPTION_FOR_MOVIE_FAILURE,
  error: string,
};

const loadFtaTvConsumptionForMovieFailure = (error: string): LoadFtaTvConsumptionForMovieFailure => {
  return {
    type: LOAD_FTA_TV_CONSUMPTION_FOR_MOVIE_FAILURE,
    error,
  };
};

export type FtaTvConsumptionForMovieActions =
  | LoadFtaTvConsumptionForMovieRequest
  | LoadFtaTvConsumptionForMovieSuccess
  | LoadFtaTvConsumptionForMovieFailure;

export const loadFtaTvConsumptionForMovie = (movieId: string, filter: string): ThunkwithAction<FtaTvConsumptionForMovieActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadFtaTvConsumptionForMovieRequest());

    var filterIds = "";
    if(filter.length > 0){
      filterIds = "?filter_ids=" + filter;
    }

    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/movie/${movieId}/fta-tv-consumption${filterIds}`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadFtaTvConsumptionForMovieSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadFtaTvConsumptionForMovieFailure(error));
    return error;
  }
};
