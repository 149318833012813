// @flow
// TODO: replace callAPI with callAPIv2
import { callAPI } from '../../../middleware';
import { getBaseUrl } from '../../../helpers';

import type { ThunkwithAction } from '../../../types';

export const LOAD_FORECAST_MOVIES_REQUEST = 'LOAD_FORECAST_MOVIES_REQUEST';
export const LOAD_FORECAST_MOVIES_SUCCESS = 'LOAD_FORECAST_MOVIES_SUCCESS';
export const LOAD_FORECAST_MOVIES_FAILURE = 'LOAD_FORECAST_MOVIES_FAILURE';

type LoadForecastMoviesRequest = {
  type: typeof LOAD_FORECAST_MOVIES_REQUEST,
};

const loadForecastMoviesRequest = (): LoadForecastMoviesRequest => {
  return {
    type: LOAD_FORECAST_MOVIES_REQUEST,
  };
};

type LoadForecastMoviesSuccess = {
  type: typeof LOAD_FORECAST_MOVIES_SUCCESS,
  payload: Object,
};

const loadForecastMoviesSuccess = (forecasts: Object): LoadForecastMoviesSuccess => {
  return {
    type: LOAD_FORECAST_MOVIES_SUCCESS,
    payload: forecasts,
  };
};

type LoadForecastMoviesFailure = {
  type: typeof LOAD_FORECAST_MOVIES_FAILURE,
  error: string,
};

const loadForecastMoviesFailure = (error: string): LoadForecastMoviesFailure => {
  return {
    type: LOAD_FORECAST_MOVIES_FAILURE,
    error,
  };
};

export type ForecastMoviesActions =
  | LoadForecastMoviesRequest
  | LoadForecastMoviesSuccess
  | LoadForecastMoviesFailure;

export const loadForecastMovies = (forecastMovieId: string): ThunkwithAction<ForecastMoviesActions> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadForecastMoviesRequest());

    const response = await callAPI({
      method: 'GET',
      targetUrl: `${getBaseUrl()}/api/forecast/${forecastMovieId}/movies`,
    });

    if (response instanceof Error) {
      throw response;
    }

    dispatch(loadForecastMoviesSuccess(response));

    return response;
  } catch (error) {
    dispatch(loadForecastMoviesFailure(error));
    return error;
  }
};
