// @flow
import * as actions from '../../../actions/forecast/common/forecastFTATVConsumption';

import type { ForecastFTAConsumptionForMovieActions } from '../../../actions/forecast/common/forecastFTATVConsumption';

type State = {
  isFetching: boolean,
  forecastFTATVConsumptionForMovieResults: Array<any>,
  message: String,
};

const initialState = {
  isFetching: false,
  forecastFTATVConsumptionForMovieResults: [],
  message: "",
};

export const forecastFTATVConsumption = (
  state: State = initialState,
  action: ForecastFTAConsumptionForMovieActions
) => {
  switch (action.type) {
    case 'RESET_FORECAST_MOVIE_PROFILING_DATA':
      return {
        ...state,
        isFetching: false,
        forecastFTATVConsumptionForMovieResults: [],
        message: ''
      }
    case actions.LOAD_FORECAST_FTA_TV_CONSUMPTION_FOR_MOVIE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_FORECAST_FTA_TV_CONSUMPTION_FOR_MOVIE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_FORECAST_FTA_TV_CONSUMPTION_FOR_MOVIE_SUCCESS:
      return {
        ...state,
        forecastFTATVConsumptionForMovieResults: action.payload.fta_tv_consumption,
        message: action.payload.message,
        isFetching: false,
      };
    default:
      return state;
  }
};
