// @flow
import * as actions from '../../../actions/forecast/common/forecastInfoStat';

import type { InfoForForecastActions } from '../../../actions/forecast/common/forecastInfoStat';

type State = {
  isFetching: boolean,
  value: string,
};

const initialState = {
  isFetching: false,
  value: "",
};

export const forecastInfoStat = (
  state: State = initialState,
  action: InfoForForecastActions
) => {
  switch (action.type) {
    case 'RESET_FORECAST_MOVIE_PROFILING_DATA':
      return {
        ...state,
        value: "",
        isFetching: false
      }
    case actions.LOAD_INFO_FOR_FORECAST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.LOAD_INFO_FOR_FORECAST_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_INFO_FOR_FORECAST_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      };
    default:
      return state;
  }
};
