// @flow
import * as actions from '../../../actions/forecast/common/forecastMovies';
import type { ForecastMoviesActions } from '../../../actions/forecast/common/forecastMovies';

type State = {
  isFetching: boolean,
  movieResults: Array<any>,
  message: String
};

const initial = {
  isFetching: false,
  movieResults: [],
  message: ''
};

export const forecastMovies = (
  state: State = initial,
  action: ForecastMoviesActions
) => {
  switch (action.type) {
    case 'RESET_FORECAST_MOVIE_PROFILING_DATA':
      return {
        ...state,
        movieResults: [],
        isFetching: false
      }
    case actions.LOAD_FORECAST_MOVIES_REQUEST:
      return {
        ...state,
        isFetching: true,
        movieResults: []
      };
    case actions.LOAD_FORECAST_MOVIES_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actions.LOAD_FORECAST_MOVIES_SUCCESS:
      return {
        ...state,
        movieResults: action.payload.forecastMovies,
        isFetching: false,
        message: action.payload.message,
      };
    default:
      return state;
  }
};
